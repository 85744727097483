<template>
  <v-img
    src="@/assets/bg_register_agency.png"
    lazy-src="@/assets/bg_register_agency_small.jpg"
    height="100vh"
    width="100%"
    class="bg-cover"
  >
    <v-container
      fluid
      class="justify-center d-flex flex-column align-center"
      style="height: 100%"
      :class="$vuetify.breakpoint.xs ? 'pa-0' : ''"
    >
      <v-card
        class="justify-center overflow-auto"
        max-width="600"
        color="white"
      >
        <v-card-title
          class="justify-center mt-3 d-flex align-center"
          style="color: #0A5B8A"
        >
          Join as a Agency
        </v-card-title>
        <v-card-title class="mx-3">
          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="name"
                label="Agency Name*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
                type="name"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="email"
                label="Agency Master Email*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                type="email"
                :rules="[rules.required, rules.email]"
                :error-messages="emailErrorMessage"
                autocomplete="off"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="py-0 mb-5">
              <AppPhoneNumberInput
                v-model="phone"
                @input="onChange"
                @update="onUpdatePhone"
                 :error="!!phoneErrorMessage"
                :error-messages="phoneErrorMessage"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="py-0">
              <v-text-field
                v-model="phone_ext"
                label="Phone Ext"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="contact_person"
                label="Contact Person*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="contact_title"
                label="Contact Title*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="address"
                label="Address*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                type="address"
                 :rules="[rules.required, rules.address]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="address2"
                label="Address 2"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-text-field
                v-model="city"
                label="City*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required, rules.city]"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-select
                v-model="state"
                label="State*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :items="stateOptions"
                :rules="[rules.required, rules.state]"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-text-field
                v-model="zip"
                label="Zip code*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                type="zip"
                autocomplete="off"
                :rules="[rules.required, rules.zip]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <input type="text" style="display:none;" />
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @input="onChange"
                placeholder="Password*"
                dense
                filled
                outlined
                background-color="white"
                class="pb-0 mb-0"
                full-width
                style="width: 100%"
                autocomplete="new-password"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <input type="text" style="display:none;" />
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword2 ? 'text' : 'password'"
                @click:append="showPassword2 = !showPassword2"
                @input="onChange"
                placeholder="Confirm password*"
                dense
                filled
                outlined
                background-color="white"
                class="pb-0 mb-0"
                full-width
                style="width: 100%"
                autocomplete="new-password"
              />
            </v-col>
          </v-row>
          <div style="width: 100%">
            <!-- <v-btn
              rounded
              color="#7024c4"
              width="100%"
              @click="onSignUp"
              :loading="loading"
              class="mt-5 white--text"
              >Join</v-btn
            > -->
                <v-btn
              v-if="!phoneErrorMessage" 
              rounded
              color="#7024c4"
              width="100%"
              @click="onSignUp"
              :loading="loading"
              class="mt-5 white--text"
            >
              <template v-if="loading">
              <v-progress-circular
                indeterminate
                color="white"
                size="20"
              ></v-progress-circular>
              </template>
              <template v-else>
              Join
              </template>
            </v-btn>

            <v-btn
              v-else 
              rounded
              color="red"
              width="100%"
              :disabled="true" 
              class="mt-5 white--text"
            >
              {{ phoneErrorMessage }}
            </v-btn>
            <v-btn
              text
              class="mt-4 mb-4 text-none"
              block
              rounded
              @click="onBack()"
              >Back to login</v-btn
            >
          </div>
        </v-card-title>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </v-img>
</template>
<script>
import { mapActions } from "vuex";
import AppPhoneNumberInput from "@/components/common/AppPhoneNumberInput.vue";
export default {
  components: {
    AppPhoneNumberInput
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      loading: false,
      snackbar: false,
      errorMessage: null,
      city: "",
      zip: "",
      state: "",
      address: "",
      address2: "",
      contact_person: "",
      contact_title: "",
      phoneErrorMessage: "",
      rules: {
        required: value => !!value || "Required.",
        min: v => (v && v.length >= 8) || "Min 8 characters",
        // email: value => {
        //   const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?<!\.)$/;
        //   return pattern.test(value) &&
        //     !/(\.\.|@\.|@-|-\.|_\.|,|#|\s)/.test(value) &&
        //     !/^[^@]+@[^@]+\.[^@]+$/.test(value) &&
        //     !value.endsWith('.') &&
        //     !value.startsWith('@') &&
        //     !value.includes('..')
        //     ? true
        //     : "Invalid email address.";
        // },
        email: value => {
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
          return pattern.test(value) || "Invalid email address.";
        },
        address: value => value.trim().length > 5 || "Address must be at least 6 characters.",
        city: value => /^[a-zA-Z\s]+$/.test(value) || "City must contain only letters.",
        state: value => this.stateOptions.includes(value) || "Invalid state selected.",
        zip: value => /^\d{5}(-\d{4})?$/.test(value) || "Invalid zip code.",
        phone: value => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : ""
      },
      showPassword: false,
      showPassword2: false,
      phone: "",
      phone_ext: "",
      emailErrorMessage: "",
      phonePayload: {},
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI"
      ]
    };
  },
  methods: {
    ...mapActions("auth", {
      registerAgency: "onRegisterAgency",
      checkEmail : "onCheckEmail",
      checkPhone: "onCheckPhone"
    }),
    onChange() {
      this.snackbar = false;
      this.errorMessage = null;
       if (this.email) {
        this.checkEmailAddress();
      }
      if (this.phone) {
        this.checkPhoneNumber();
      }
    },
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    },
    showSnackbar(message) {
      this.snackbar = true;
      this.errorMessage = message;
    },
        checkEmailAddress() {
      let params = {
        email: this.email
      };
      this.checkEmail(params)
        .then(() => {
          this.loading = false;
          this.emailErrorMessage = "";
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showSnackbar(error.response.data.message);
            this.emailErrorMessage = error.response.data.message;
          }
        });
    },
        checkPhoneNumber() {
      let params = {
        phone: this.phone
      };
      this.checkPhone(params)
        .then(() => {
          this.loading = false;
          this.phoneErrorMessage = "";
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.phoneErrorMessage = error.response.data.message;
          }
        });
    },
    onBack() {
      this.$router.push({ name: "login" });
    },
    onSignUp() {
  let isValid = true;

  if (!this.name) {
    this.errorMessage = "Name is required";
    isValid = false;
  }
  if (!this.email) {
    this.errorMessage = "Email is required";
    isValid = false;
  } else if (this.rules.email(this.email) !== true) {
    this.errorMessage = "Invalid email";
    isValid = false;
  }
  if (!this.phone) {
    this.errorMessage = "Phone is required";
    isValid = false;
  } else if (!this.phonePayload.isValid) {
    this.errorMessage = "Invalid phone number";
    isValid = false;
  }
  if (!this.contact_person) {
    this.errorMessage = "Contact person is required";
    isValid = false;
  }
  if (!this.contact_title) {
    this.errorMessage = "Contact title is required";
    isValid = false;
  }
  if (!this.address) {
    this.errorMessage = "Address is required";
    isValid = false;
  }
  if (!this.city || this.city.trim().length === 0) {
    this.errorMessage = "City is required and cannot be empty";
    isValid = false;
  }
  if (!this.state || !this.stateOptions.includes(this.state)) {
    this.errorMessage = "State is required and must be valid";
    isValid = false;
  }
  if (!this.zip || !/^\d{5}(-\d{4})?$/.test(this.zip)) {
    this.errorMessage = "Zip code is required and must be valid";
    isValid = false;
  }
  if (!this.password) {
    this.errorMessage = "Password is required";
    isValid = false;
  } else if (this.password.length < 8) {
    this.errorMessage = "Password must be at least 8 characters";
    isValid = false;
  }
  if (!this.confirmPassword) {
    this.errorMessage = "Confirm Password is required";
    isValid = false;
  } else if (this.password !== this.confirmPassword) {
    this.errorMessage = "Passwords do not match";
    isValid = false;
  }

  if (!isValid) {
    this.snackbar = true;
    return;
  }

  this.loading = true;
  let phone = this.phone;
  if (this.phonePayload.isValid && this.phonePayload.countryCallingCode !== "1") {
    phone = `+${this.phonePayload.countryCallingCode} ${phone}`;
  }

  this.registerAgency({
    name: this.name,
    email: this.email,
    phone,
    phone_ext: this.phone_ext,
    contact_person: this.contact_person,
    contact_title: this.contact_title,
    address: this.address,
    address2: this.address2,
    city: this.city,
    state: this.state,
    zip: this.zip,
    password: this.password,
  })
    .then(() => {
      this.loading = false;
      this.$router.push({
        name: "agency-home",
        query: { welcome: true },
      });
    })
    .catch((error) => {
      this.loading = false;
      this.errorMessage = error.response?.data?.message || "Registration failed";
      this.snackbar = true;
    });
}

  }
};
</script>
<style scoped>
.bg-cover :deep(.v-image__image) {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.v-responsive__content {
  height: 100%;
}
</style>
